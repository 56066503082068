import React, { Dispatch, SetStateAction, createContext, useEffect, useMemo, useState } from 'react';
import { BaseArea } from '@styles/Common';
import styled, { css } from 'styled-components';
import { A } from '@styles/Common';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
	useCreateProcessCopy,
	useCreateUnitProcess,
	useFileUpload,
	useGetAttachedFileBase64Image,
	useGetCategoriesList,
	useGetDataQualityIndicators,
	useGetEnums,
	useGetLciResults,
	useGetLciaModels,
	useGetProcessForProcessView,
	useGetReviewLevelType,
	usePutProcessHistories,
	useUpdateProcess,
	useGetProcessIosExcel,
	useGetUserDetail,
	useGetUnitGroups,
	useLciaResults,
	useGetProcessCalculatedDate,
	useGetUnitConversions,
	useGetUnitGroupsMass,
	useGetUnitGroupsCurrency
} from '@hooks/useBackendApi';

import IOInfo from '@specific/process/process_view/IOInfo';
import AdditionInfo from '@specific/process/process_view/AdditionInfo';
import ProsessAllocation from '@specific/process/process_view/ProsessAllocation';
import ProcessParameterSetting from '@specific/process/process_view/ProcessParameterSetting';
import ProcessCalculation from '@specific/process/process_view/ProcessCalculation';
import {
	GetProcessType,
	ProcessIosType,
	UpdateProcess,
	ProcessParametersType,
	CategoriesList,
	DataQualityIndicators,
	ReviewLevelType,
	ImpactAssesmentMethods,
	AllocationOut,
	Cutoff,
	DisposalOut,
	AlternativeOut,
	ProcessCalculate,
	GetUserDetail,
	UnitGroupList,
	LCIAResults,
	CalculatedDateOut,
	UnitConversionsList
} from '@typeList/types';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AlertDialog from '@common/modal/AlertDialog';
import AlertDialogDisable from '@common/modal/AlertDialogDisable';
import { getCurrentUserInfo } from '@services/Cookies';
import { formatToLocalTime, formatDateTimeForInput, checkPrameterName, getMaxProcessIoNo } from '@utils/utils';
import ErrorMsg from '@common/error/ErrorMsg';
import TextTreeDisplay from '@specific/process/process_view/TextTreeDisplay';
import { FadeLoader } from 'react-spinners';
import MessageDaialog from '@common/modal/MessageDaialog';
import { use } from 'i18next';
import LoadingDialog from '@common/modal/LoadingDialog';

interface ProcessViewContextType {
	userData: GetUserDetail | null;
	unitGroupsData: UnitGroupList[] | null
	processData: GetProcessType | null;
	processIos: ProcessIosType[] | undefined;
	inElementaryFlow: ProcessIosType[] | undefined;
	outElementaryFlow: ProcessIosType[] | undefined;
	inProduct: ProcessIosType[] | undefined;
	outProduct: ProcessIosType[] | undefined;
	databasePacks: number | undefined;
	processParameters: ProcessParametersType[] | undefined;
	updateProcessParameters: ProcessParametersType[] | undefined;
	categoriesListData: CategoriesList[] | null;
	enumsData: any[] | null;
	dataQualityIndicatorsData: DataQualityIndicators[] | null;
	selectQualityIndicatorsData: any[] | undefined;
	qualityIndicatorsDataList: any[] | undefined;
	reviewLevelTypeData: ReviewLevelType[] | null;
	selectReviewLevelData: any[] | undefined;
	reviewLevelsDataList: any[] | undefined;
	inputAllocationType: string;
	otherRelationshipUnitName: string;
	unsaved: boolean;
	setUnsaved: Dispatch<SetStateAction<boolean>>;
	setProcessIos: Dispatch<SetStateAction<ProcessIosType[] | undefined>>;
	setInElementaryFlow: Dispatch<SetStateAction<ProcessIosType[] | undefined>>;
	setOutElementaryFlow: Dispatch<SetStateAction<ProcessIosType[] | undefined>>;
	setInProduct: Dispatch<SetStateAction<ProcessIosType[] | undefined>>;
	setOutProduct: Dispatch<SetStateAction<ProcessIosType[] | undefined>>;
	setProcessParameters: Dispatch<SetStateAction<ProcessParametersType[] | undefined>>;
	setUpdateProcessParameters: Dispatch<SetStateAction<ProcessParametersType[] | undefined>>;
	setSelectQualityIndicatorsData: Dispatch<SetStateAction<any[] | undefined>>;
	setQualityIndicatorsDataList: Dispatch<SetStateAction<any[] | undefined>>;
	setSelectReviewLevelData: Dispatch<SetStateAction<any[] | undefined>>;
	setReviewLevelsDataList: Dispatch<SetStateAction<any[] | undefined>>;
	setInputAllocationType: Dispatch<SetStateAction<string>>;
	setOtherRelationshipUnitName: Dispatch<SetStateAction<string>>;
	otherRelationshipName: string;
	inputAllocationMethod: string;
	inputAllocationComment: string;
	inputAllocationCoProductName: string;
	inputAllocationCoProductComment: string;
	setOtherRelationshipName: Dispatch<SetStateAction<string>>;
	setInputAllocationMethod: Dispatch<SetStateAction<string>>;
	setInputAllocationComment: Dispatch<SetStateAction<string>>;
	setInputAllocationCoProductName: Dispatch<SetStateAction<string>>;
	setInputAllocationCoProductComment: Dispatch<SetStateAction<string>>;
	extendProperty: Record<string, any> | null | undefined;
	updateExtendPropertys: Record<string, any> | null | undefined;
	setExtendProperty: Dispatch<SetStateAction<Record<string, any> | null | undefined>>;
	setUpdateExtendPropertys: Dispatch<SetStateAction<Record<string, any> | null | undefined>>;
	methodsData: ImpactAssesmentMethods[] | null;
	previewImage: string | null;
	setPreviewImage: Dispatch<SetStateAction<string | null>>;
	imageFile: File | undefined;
	setImageFile: Dispatch<SetStateAction<File | undefined>>;
	imageSrc: string | null;
	setImageSrc: Dispatch<SetStateAction<string | null>>;
	imageName: string | null;
	setImageName: Dispatch<SetStateAction<string | null>>;
	referenceFiles: ReferenceFile[];
	setReferenceFiles: Dispatch<SetStateAction<ReferenceFile[]>>;
	inputCharacterizationDisplayFormatValue: string;
	setInputCharacterizationDisplayFormatValue: Dispatch<SetStateAction<string>>;
	onSubmit: SubmitHandler<UpdateProcess>;
	resultData: ProcessCalculate[] | null;
	setResultData: Dispatch<SetStateAction<ProcessCalculate[] | null>>;
	changeCheckAdditionInfo: boolean;
	setChangeCheckAdditionInfo: Dispatch<SetStateAction<boolean>>;
	changeCheckExtendProperty: boolean;
	setChangeCheckExtendProperty: Dispatch<SetStateAction<boolean>>;
	changeCheckParameter: boolean;
	setchangeCheckParameter: Dispatch<SetStateAction<boolean>>;
	changeCheckFlow: boolean;
	setChangeCheckFlow: Dispatch<SetStateAction<boolean>>;
	segregatedData: DirectionTypeState;
	changeCheckAllocation: boolean;
	setChangeCheckAllocation: Dispatch<SetStateAction<boolean>>;
	isInitializedAllocationInfo: boolean;
	setIsInitializedAllocationInfo: Dispatch<SetStateAction<boolean>>;
	lciaResultsData: LCIAResults[] | null;
	setLciaResultsData: React.Dispatch<React.SetStateAction<LCIAResults[] | null>>;
	lciaResultsLoading: boolean;
	lciaResultsError: any;
	selectModelIdList: number[];
	setSelectModelIdList: React.Dispatch<React.SetStateAction<number[]>>;
	lciResultsData: ProcessCalculate[] | null
	setLciResultsData: React.Dispatch<React.SetStateAction<ProcessCalculate[] | null>>
	lciResultsLoading: boolean;
	setSavedAlertEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	getLciData: () => Promise<void>;
	calculateAndGetResultsLoading: boolean;
	setCalculateAndGetResultsLoading: React.Dispatch<React.SetStateAction<boolean>>;
	calculatedDateData: CalculatedDateOut | null;
	getLciCalculatedDate: () => Promise<void>;
	treeData: any[];
	setTreeData: React.Dispatch<React.SetStateAction<any[]>>;
	unitConversionsData: UnitConversionsList[] | null;
	kgId: number | undefined;
	userDefaultCurrencyId: number | undefined;
	userDefaultCurrencyName: string | undefined;
	setMassStandardError: React.Dispatch<React.SetStateAction<boolean>>;
	setMarketPriceStandardError: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaulutContextvalue = {
	userData: null,
	unitGroupsData: null,
	processData: null,
	processIos: [],
	inElementaryFlow: [],
	outElementaryFlow: [],
	inProduct: [],
	outProduct: [],
	databasePacks: 1,
	processParameters: [],
	updateProcessParameters: [],
	categoriesListData: [],
	enumsData: [],
	dataQualityIndicatorsData: [],
	selectQualityIndicatorsData: [],
	qualityIndicatorsDataList: [],
	reviewLevelTypeData: [],
	selectReviewLevelData: [],
	reviewLevelsDataList: [],
	inputAllocationType: "mass",
	otherRelationshipUnitName: "",
	unsaved: false,
	setUnsaved: () => { },
	setProcessIos: () => { },
	setInElementaryFlow: () => { },
	setOutElementaryFlow: () => { },
	setInProduct: () => { },
	setOutProduct: () => { },
	setProcessParameters: () => { },
	setUpdateProcessParameters: () => { },
	setSelectQualityIndicatorsData: () => { },
	setQualityIndicatorsDataList: () => { },
	setSelectReviewLevelData: () => { },
	setReviewLevelsDataList: () => { },
	setInputAllocationType: () => { },
	setOtherRelationshipUnitName: () => { },
	otherRelationshipName: "",
	inputAllocationMethod: "",
	inputAllocationComment: "",
	inputAllocationCoProductName: "",
	inputAllocationCoProductComment: "",
	setOtherRelationshipName: () => { },
	setInputAllocationMethod: () => { },
	setInputAllocationComment: () => { },
	setInputAllocationCoProductName: () => { },
	setInputAllocationCoProductComment: () => { },
	extendProperty: {},
	updateExtendPropertys: {},
	setExtendProperty: () => { },
	setUpdateExtendPropertys: () => { },
	methodsData: null,
	previewImage: null,
	setPreviewImage: () => { },
	imageSrc: null,
	setImageSrc: () => { },
	imageName: null,
	setImageName: () => { },
	imageFile: undefined,
	setImageFile: () => { },
	referenceFiles: [],
	setReferenceFiles: () => { },
	inputCharacterizationDisplayFormatValue: "1",
	setInputCharacterizationDisplayFormatValue: () => { },
	onSubmit: () => { },
	resultData: [],
	setResultData: () => { },
	changeCheckAdditionInfo: false,
	setChangeCheckAdditionInfo: () => { },
	changeCheckExtendProperty: false,
	setChangeCheckExtendProperty: () => { },
	changeCheckParameter: false,
	setchangeCheckParameter: () => { },
	changeCheckFlow: false,
	setChangeCheckFlow: () => { },
	segregatedData: {
		in_elementary_flow: [],
		out_elementary_flow: [],
		in_product: [],
		out_product: []
	},
	changeCheckAllocation: false,
	setChangeCheckAllocation: () => { },
	isInitializedAllocationInfo: false,
	setIsInitializedAllocationInfo: () => { },
	lciaResultsData: null,
	setLciaResultsData: () => { },
	lciaResultsLoading: false,
	lciaResultsError: "",
	selectModelIdList: [],
	setSelectModelIdList: () => { },
	lciResultsData: null,
	setLciResultsData: () => { },
	lciResultsLoading: false,
	setSavedAlertEnabled: () => { },
	getLciData: async (): Promise<void> => { },
	calculateAndGetResultsLoading: false,
	setCalculateAndGetResultsLoading: () => { },
	calculatedDateData: null,
	getLciCalculatedDate: async (): Promise<void> => { },
	treeData: [],
	setTreeData: () =>{},
	unitConversionsData: [],
	kgId: 0,
	userDefaultCurrencyId: 0,
	userDefaultCurrencyName: "",
	setMassStandardError: () => {},
	setMarketPriceStandardError: () => {}
}

interface DirectionTypeState {
	in_elementary_flow: ProcessIosType[];
	out_elementary_flow: ProcessIosType[];
	in_product: ProcessIosType[];
	out_product: ProcessIosType[];
};

interface ReferenceFile {
	name: string;
	file: File;
}

const segregateByDirection = (data: ProcessIosType[] | undefined): DirectionTypeState => {
	const directionTypeState: DirectionTypeState = {
		in_elementary_flow: [],
		out_elementary_flow: [],
		in_product: [],
		out_product: []
	};

	data?.forEach(io => {
		if (io.direction === "in") {
			if (io.exchange.type === "elementary_flow") {
				directionTypeState.in_elementary_flow.push(io);
			} else if (io.exchange.type === "product") {
				directionTypeState.in_product.push(io);
			}
		} else if (io.direction === "out") {
			if (io.exchange.type === "elementary_flow") {
				directionTypeState.out_elementary_flow.push(io);
			} else if (io.exchange.type === "product") {
				directionTypeState.out_product.push(io);
			}
		}
	})
	return directionTypeState;
};


export const ProcessViewContext = createContext<ProcessViewContextType>(defaulutContextvalue);

/**
 * プロセス閲覧画面Base
 * 
 */
const ProcessView = () => {
	// ユーザー情報
	const { userData } = useGetUserDetail(getCurrentUserInfo()?.id);

	// 初期データ取得フラグ
	const [initialDisplayFlag, setInitialDisplayFlag] = useState<boolean>(false)

	// 言語設定
	const { i18n, t } = useTranslation();
	useEffect(() => {
		let lng = userData?.default_locale_code;
		if (!lng) {
			lng = 'ja'
		}

		if (lng) {
			i18n.changeLanguage(lng);
			document.documentElement.lang = lng;
		}
	}, [userData]);

	const params = useParams();
	const { control, watch, register, handleSubmit, formState: { errors } } = useForm<UpdateProcess>();

	// プロセス
	const { getProcessForProcessView, processData, processError, processLoading } = useGetProcessForProcessView()
	useEffect(() => {
		try {
			getProcessForProcessView(Number(params.id))
		} catch (error) {
			console.log(error);
		}
	}, [params])


	// マスタのデータ以外の他人のプロセスを展開させない
	const navigate = useNavigate();
	useEffect(() =>{
		if( userData && processData && !processData.is_database_pack_master && (userData.username !== processData.created_by_username)) {
			navigate("/");		
		}
	},[userData, processData])


	// 閲覧履歴
	const { putProcessHistories } = usePutProcessHistories()
	useEffect(() => {
		if (processData && !initialDisplayFlag) {
			try {
				putProcessHistories(processData.id);
			} catch (error) {
				console.log(error);
			}
		}
	}, [processData])

	// プロセス計算日時
	const {getCalculatedDate, calculatedDateData, calculatedDateLoading} = useGetProcessCalculatedDate();
	const getLciCalculatedDate = async (): Promise<void> => {
		try {
			if (processData) {
				getCalculatedDate(processData?.id);
			}
		} catch (error) {
			console.log(error);
		}
	}
	useEffect(() =>{
		if(processData) {
			getLciCalculatedDate();
		}
	},[processData])


	// 計算からLCIAの結果を取得するまでのローディングを管理
	const [calculateAndGetResultsLoading, setCalculateAndGetResultsLoading] = useState<boolean>(false);

	// プロセス計算結果
	const [resultData, setResultData] = useState<ProcessCalculate[] | null>(null);
	const { getLciResults, lciResultsData, setLciResultsData, lciResultsLoading } = useGetLciResults();

	const getLciData = async (): Promise<void> => {
		try {
			if (processData) {
				getLciCalculatedDate();
				getLciResults(processData?.id);
			}
		} catch (error) {
			console.log(error);
		}
	}


	useEffect(() => {
		setResultData(lciResultsData);
	}, [lciResultsData]);


	// フロー
	const [processIos, setProcessIos] = useState<ProcessIosType[] | undefined>();
	// データ表示用に分割
	const segregatedData = segregateByDirection(processData?.process_ios);

	const [inElementaryFlow, setInElementaryFlow] = useState<ProcessIosType[] | undefined>();
	const [outElementaryFlow, setOutElementaryFlow] = useState<ProcessIosType[] | undefined>();
	const [inProduct, setInProduct] = useState<ProcessIosType[] | undefined>();
	const [outProduct, setOutProduct] = useState<ProcessIosType[] | undefined>();
	const [databasePacks, setDatabasePacks] = useState<number>();

	// process_io_noを付与してセット
	const updateProcessIosProcessIoNo = (items: ProcessIosType[] | undefined, setFunction: Dispatch<SetStateAction<ProcessIosType[] | undefined>>) => {
		if (items) {
			const updatedItems = items.map(item => ({
				...item,
				process_io_no: item.process_io_no ?? getMaxProcessIoNo(inProduct, inElementaryFlow, outProduct, outElementaryFlow) + 1
			}));
			setFunction(updatedItems);
		}
	};

	useEffect(() => {
		setProcessIos(processData?.process_ios);
		updateProcessIosProcessIoNo(segregatedData.in_product, setInProduct);
		updateProcessIosProcessIoNo(segregatedData.in_elementary_flow, setInElementaryFlow);
		updateProcessIosProcessIoNo(segregatedData.out_product, setOutProduct);
		updateProcessIosProcessIoNo(segregatedData.out_elementary_flow, setOutElementaryFlow);

		setDatabasePacks(processData?.database_pack.id)
	}, [processData])

	// パラメーター
	const [processParameters, setProcessParameters] = useState<ProcessParametersType[] | undefined>();
	const [updateProcessParameters, setUpdateProcessParameters] = useState<ProcessParametersType[] | undefined>();
	useEffect(() => {
		setProcessParameters(processData?.process_parameters);
	}, [processData])

	useEffect(() => {
		setUpdateProcessParameters(processParameters?.filter(
			(param) => param.hasOwnProperty('name') && param.hasOwnProperty('value')
		));
	}, [processParameters])

	// process_parametersの加工
	const filteredProcessParameters = updateProcessParameters?.filter(
		(param) => param.hasOwnProperty('name') && param.hasOwnProperty('value'))
		.map((param) => ({
			name: param.name,
			value: param.value,
		}));

	// 拡張プロパティ
	const [extendProperty, setExtendProperty] = useState<Record<string, any> | null | undefined>();
	const [updateExtendPropertys, setUpdateExtendPropertys] = useState<Record<string, any> | null | undefined>();
	useEffect(() => {
		setExtendProperty(processData?.extend_property);
		setUpdateExtendPropertys(processData?.extend_property);
	}, [processData])

	// カテゴリ一覧
	const { categoriesListData } = useGetCategoriesList();

	// 適合度
	const { enumsData } = useGetEnums();

	// 品質
	const { dataQualityIndicatorsData, getDataQualityIndicators } = useGetDataQualityIndicators(); // 品質の一覧
	const [selectQualityIndicatorsData, setSelectQualityIndicatorsData] = useState<any[] | undefined>(); // 選択された品質（更新用）
	const [qualityIndicatorsDataList, setQualityIndicatorsDataList] = useState<any[] | undefined>(); // テーブルでの表示用 
	useEffect(() => {
		if (!dataQualityIndicatorsData || dataQualityIndicatorsData.length === 0) {
			getDataQualityIndicators();
		}
	}, [processData])

	// レビューレベル
	const { reviewLevelTypeData, getReviewLevelType } = useGetReviewLevelType(); //レビュータイプの一覧
	const [selectReviewLevelData, setSelectReviewLevelData] = useState<any[] | undefined>(); // 選択されたレビュータイプ（更新用）
	const [reviewLevelsDataList, setReviewLevelsDataList] = useState<any[] | undefined>(); // テーブルでの表示用 
	useEffect(() => {
		if (!reviewLevelTypeData || reviewLevelTypeData.length === 0) {
			getReviewLevelType();
		}
	}, [processData])

	// 画像
	const [previewImage, setPreviewImage] = useState<string | null>(null);
	const [imageSrc, setImageSrc] = useState<string | null>(null);
	const [imageName, setImageName] = useState<string | null>(null);
	const { getAttachedFileBase64Image } = useGetAttachedFileBase64Image();
	useEffect(() => {
		const fetchImage = async () => {
			try {
				if (processData?.process_attached_files && processData.process_attached_files.length > 0) {
					const technologyImageFile = processData.process_attached_files.filter(file => file.is_technology_image === true)[0];
					if (technologyImageFile) {
						const base64Data = await getAttachedFileBase64Image(technologyImageFile.id || 0);
						setImageSrc(base64Data?.base64_data);
						setImageName(base64Data.filename);
					}
				}
			} catch (error) {
				console.error(error);
			}
		};
		fetchImage();
	}, [processData]);

	// ファイルアップロード
	const [imageFile, setImageFile] = useState<File>();
	const [imageFileId, setImageFileId] = useState<number>();
	const { fileUpload, fileUploadData } = useFileUpload();
	useEffect(() => {
		if (fileUploadData) {
			setImageFileId(fileUploadData.id)
		}
	}, [fileUploadData])

	// 参考ファイル
	const [referenceFiles, setReferenceFiles] = useState<ReferenceFile[]>([]);
	// useEffectで非技術画像のファイルをセット
	useEffect(() => {
		const fetchFiles = async () => {
			if (processData) {
				// is_technology_imageがfalseのファイルのみをフィルタリング
				const filteredFiles = processData.process_attached_files.filter(file => !file.is_technology_image);

				// ファイルオブジェクトを生成し、ReferenceFileのリストを作成
				const referenceFilesList = await Promise.all(
					filteredFiles.map(async (file) => {
						// ファイルデータを取得
						const response = await fetch(file.storage_path);
						const blob = await response.blob();
						const fileObject = new File([blob], file.filename, { type: blob.type });
						return { name: file.filename, file: fileObject };
					})
				);

				// 生成したリストを状態にセット
				setReferenceFiles(referenceFilesList);
			}
		};

		fetchFiles();
	}, [processData]);


	// 配分
	const [isInitializedAllocationInfo, setIsInitializedAllocationInfo] = useState<boolean>(false); // 配分を初期化済みか
	const [inputAllocationType, setInputAllocationType] = useState<string>(processData?.allocation_type || "mass");
	const [otherRelationshipName, setOtherRelationshipName] = useState<string>(processData?.other_relationship_name || "");
	const [otherRelationshipUnitName, setOtherRelationshipUnitName] = useState<string>(processData?.other_relationship_unit_name || "");

	const [inputAllocationMethod, setInputAllocationMethod] = useState<string>(processData?.allocation_method || ""); // 出力の取り扱い：配分手法
	const [inputAllocationComment, setInputAllocationComment] = useState<string>(processData?.allocation_comment || ""); // 出力の取り扱い：配分コメント]
	const [inputAllocationCoProductName, setInputAllocationCoProductName] = useState<string>(processData?.allocation_co_product_name || ""); // 出力の取り扱い：共製品
	const [inputAllocationCoProductComment, setInputAllocationCoProductComment] = useState<string>(processData?.allocation_co_product_comment || ""); // 出力の取り扱い：共製品コメント

	useEffect(() => {
		setInputAllocationType(processData?.allocation_type || "mass")
		setOtherRelationshipName(processData?.other_relationship_name || "");
		setOtherRelationshipUnitName(processData?.other_relationship_unit_name || "")
		setInputAllocationMethod(processData?.allocation_method || "")
		setInputAllocationComment(processData?.allocation_comment || "")
		setInputAllocationCoProductName(processData?.allocation_co_product_name || "")
		setInputAllocationCoProductComment(processData?.allocation_co_product_comment || "")
	}, [processData])

	// 単位換算
	const { getUnitConversions, unitConversionsData, unitConversionsError, unitConversionsLoading } = useGetUnitConversions();
	useEffect(() => {
        try {
            if (processData && (!unitConversionsData || unitConversionsData.length === 0)) {
                getUnitConversions(processData.database_pack.id);
            }
        } catch (error) {
            console.log(error);
        }
    }, [processData])

	// 重量基準の取得
	const {getUnitGroupsMass, unitGroupsMassData} = useGetUnitGroupsMass()
	const [kgId, setKgId] = useState<number>(); 
	useEffect(() =>{
		try {
			if(processData){
				getUnitGroupsMass(processData.database_pack.id)
			}
		} catch (error) {
			console.log(error)
		}
	},[processData?.database_pack.id])

	useEffect(() =>{
        // KgのIdでフィルター
        if(unitGroupsMassData) {
            setKgId(unitGroupsMassData.units.find(unit => unit.code === "kg")?.id)
        }
    },[unitGroupsMassData])

	// 通貨基準
	const {getUnitGroupsCurrency, unitGroupsCurrencyData} = useGetUnitGroupsCurrency();
	const [userDefaultCurrencyId, setUserDefaultCurrencyId] = useState<number>();
	const [userDefaultCurrencyName, setUserDefaultCurrencyName] = useState<string>();
	
	useEffect(() =>{
		try {
			if(processData){
				getUnitGroupsCurrency(processData.database_pack.id)
			}
		} catch (error) {
			console.log(error)
		}
	},[processData?.database_pack.id])

	useEffect(() =>{
        // userDefaultCurrencyIdでフィルター
        if(unitGroupsCurrencyData) {
            setUserDefaultCurrencyId(unitGroupsCurrencyData.units.find(unit => unit.code === getCurrentUserInfo()?.default_currency_code)?.id)
			setUserDefaultCurrencyName(unitGroupsCurrencyData.units.find(unit => unit.code === getCurrentUserInfo()?.default_currency_code)?.name)
        }
    },[unitGroupsCurrencyData])

	// 質量基準のエラー
	const [massStandardError, setMassStandardError] = useState<boolean>(false);
	// 市場価格基準のエラー
	const [marketPriceStandardError, setMarketPriceStandardError] = useState<boolean>(false);


	// 特性化
	const [inputCharacterizationDisplayFormatValue, setInputCharacterizationDisplayFormatValue] = useState<string>("1");// 表示形式
	const [methodsData, setMethodsData] = useState<ImpactAssesmentMethods[] | null>(null);
	const {
		getLciaModels,
		lciaModelsData,
	} = useGetLciaModels();
	useEffect(() => {
		try {
			if (!initialDisplayFlag && !lciaModelsData　&& processData) {
				getLciaModels(processData.database_pack.id ?? 1, "midpoint");
			}
		} catch (error) {
			console.log(error);
		}
	}, [processData]);

	useEffect(() => {
		setMethodsData(lciaModelsData);
	}, [lciaModelsData]);


	// LCIAモデル
	const [selectModelIdList, setSelectModelIdList] = useState<Array<number>>([])
	useEffect(() => {
		if (methodsData && methodsData.length > 0) {
			const selectedModelIds = methodsData?.filter((method: ImpactAssesmentMethods) => method.is_default_selected)
				.map((method: ImpactAssesmentMethods) => method.id);

			setSelectModelIdList(selectedModelIds || []);
		}
	}, [methodsData])


	// プロセスの特性化結果を作成して取得する
	// モデル選択時
	const { excuteLciaResults, lciaResultsData, setLciaResultsData, lciaResultsLoading, lciaResultsError } = useLciaResults();
	useEffect(() => {
		if (lciResultsData && lciResultsData.length > 0 && processData && selectModelIdList && selectModelIdList.length > 0) {
			try {
				// excuteLciaResultsに抽出したIDリストを引数として渡す
				excuteLciaResults(processData.id, selectModelIdList);
			} catch (error) {
				console.error('LCIA Results Error:', error);
			} finally {
				setCalculateAndGetResultsLoading(false)
			}
		}
	}, [selectModelIdList]);

	// 計算実行時
	useEffect(() => {
		if (lciResultsData && lciResultsData.length > 0 && processData && selectModelIdList && selectModelIdList.length > 0) {
			try {
				// excuteLciaResultsに抽出したIDリストを引数として渡す
				excuteLciaResults(processData.id, selectModelIdList);
			} catch (error) {
				console.error('LCIA Results Error:', error);
			} finally {
				setCalculateAndGetResultsLoading(false)
			}
		}
	}, [lciResultsData]);



	// 選択されたタブを管理するステート
	const [activeTab, setActiveTab] = useState<string>('tab1');
	const handleTabClick = (tab: string) => {
		setActiveTab(tab);
	};

	// 保存状態のチェック
	const [unsaved, setUnsaved] = useState<boolean>(false);

	// 入出力の変更監視
	const [changeCheckFlow, setChangeCheckFlow] = useState<boolean>(false);
	const [changeCheckAllocation, setChangeCheckAllocation] = useState<boolean>(false);
	const [changeCheckAdditionInfo, setChangeCheckAdditionInfo] = useState<boolean>(false);
	const [changeCheckExtendProperty, setChangeCheckExtendProperty] = useState<boolean>(false);
	const [changeCheckParameter, setchangeCheckParameter] = useState<boolean>(false);

	// ページのリロードやブラウザバック時に警告
	const [isBlocking, setIsBlocking] = useState(false);

	useEffect(() =>{
		if(	changeCheckFlow ||
			changeCheckAdditionInfo ||
			changeCheckExtendProperty ||
			changeCheckParameter ||
			changeCheckAllocation
		) {
			setIsBlocking(true)
		} else {
			setIsBlocking(false);
		}
	},[changeCheckFlow, changeCheckAllocation, changeCheckAdditionInfo, changeCheckExtendProperty, changeCheckParameter])

	// ページリロードやブラウザバック時に警告
	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (isBlocking) {
				event.preventDefault();
				event.returnValue = ""; // 一部ブラウザで必要
			}
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
		  window.removeEventListener("beforeunload", handleBeforeUnload);
		};

	}, [isBlocking]); // isBlocking の変更を監視


	// 保存処理
	const { updateProcess, updateProcessLoading } = useUpdateProcess();
	// 更新用の入出力フロー
	const [updateProcessIos, setUpdateProcessIos] = useState<any[]>([]);

	// 変更を破棄
	const hundleUndo = async () => {
		const isConfirmed = window.confirm(t('変更を破棄しますか？'));
		if (isConfirmed) {
			try {
				window.location.reload();
			} catch (error) {
				console.log("Error: ", error)
			}
		}
	}

	useEffect(() => {
		// 初期化
		setUpdateProcessIos([]);

		// 更新用にフローを変換
		if (inProduct && inProduct.length > 0) {
			const filterInProduct = filterInProductData(inProduct)
			setUpdateProcessIos(prevState => [...prevState, ...filterInProduct]);
		}

		if (inElementaryFlow && inElementaryFlow.length > 0) {
			const filterInElementaryFlow = filterInElementaryFlowData(inElementaryFlow)
			setUpdateProcessIos(prevState => [...prevState, ...filterInElementaryFlow]);
		}

		if (outProduct && outProduct.length > 0) {
			const filterOutProduct = filterOutProductData(outProduct)
			setUpdateProcessIos(prevState => [...prevState, ...filterOutProduct]);
		}

		if (outElementaryFlow && outElementaryFlow.length > 0) {
			const filterOutElementaryFlow = filterOutElementaryFlowData(outElementaryFlow)
			setUpdateProcessIos(prevState => [...prevState, ...filterOutElementaryFlow]);
		}
	}, [inProduct, inElementaryFlow, outProduct, outElementaryFlow])

	// ボタン連打制御
	const [isDisabled, setIsDisabled] = useState(false);
	useEffect(() => {
		if (updateProcessLoading) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [updateProcessLoading])

	// アラート表示のフラグをuseStateで管理
	const [alertSavedEnabled, setSavedAlertEnabled] = useState<boolean>(false);

	// 保存
	const onSubmit: SubmitHandler<UpdateProcess> = async data => {
		if (isDisabled) return;

		setInitialDisplayFlag(true);

		// 画像
		let Files = [];
		// 初期データ
		if (processData?.process_attached_files && processData.process_attached_files.length > 0) {
			// setImageSrc が存在する場合
			if (imageSrc) {
				const productSystemSummaryFile = processData.process_attached_files.filter(file => file.is_technology_image === true)[0];
				if (productSystemSummaryFile) {
					// Files に product_system_summary のファイルをセット
					Files.push({
						id: productSystemSummaryFile.id,
						is_technology_image: productSystemSummaryFile.is_technology_image,
					});
				}
			}
		}
		// 画像新規追加
		if (imageFile) {
			// is_technology_image: true,のデータをFilesから除外
			Files = Files.filter(file => !file.is_technology_image);

			const response = await fileUpload(imageFile);
			Files.push({ id: response.id, is_technology_image: true });
		}
		// 参考ファイル

		if (referenceFiles.length > 0) {
			for (const fileData of referenceFiles) {
				const response = await fileUpload(fileData.file);
				Files.push({ id: response.id, is_technology_image: false });
			}
		}

		const formatDate = (date: Date, isEndOfDay: boolean = false) => {
			console.log("data", date)
			if (!date || isNaN(date.getTime())) {
				return null;
			}
			if (isEndOfDay) {
				date.setHours(23, 59, 59, 999); // 23:59:59.999 に設定
			} else {
				date.setHours(0, 0, 0, 0); // 00:00:00.000 に設定
			}

			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			const seconds = String(date.getSeconds()).padStart(2, '0');

			return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
		};

		const formattedRetrievalStartAt = data?.data_retrieval_start_at === undefined
			? processData?.data_retrieval_start_at
			: formatDate(new Date(data?.data_retrieval_start_at || ''), false);

		const formattedRetrievalEndAt = data?.data_retrieval_end_at === undefined
			? processData?.data_retrieval_end_at
			: formatDate(new Date(data?.data_retrieval_end_at || ''), true);

		const updateParam =
		{
			locale: processData?.locale,
			name: data?.name ?? processData?.name,
			tags: data?.tags ?? processData?.tags,
			public_comment: data?.public_comment ?? processData?.public_comment,
			private_comment: data?.private_comment ?? processData?.private_comment,
			technical_feature: data?.technical_feature ?? processData?.technical_feature,
			technical_content_and_functionality: data?.technical_content_and_functionality ?? processData?.technical_content_and_functionality,
			technical_scope: data?.technical_scope ?? processData?.technical_scope,
			intended_application: data?.intended_application ?? processData?.intended_application,
			information_sources: data?.information_sources ?? processData?.information_sources,
			data_selection_principle: data?.data_selection_principle ?? processData?.data_selection_principle,
			application_principle: data?.application_principle ?? processData?.application_principle,
			criteria_for_excluding_elementary_flow: data?.criteria_for_excluding_elementary_flow ?? processData?.criteria_for_excluding_elementary_flow,
			criteria_for_excluding_intermediate_flow: data?.criteria_for_excluding_intermediate_flow ?? processData?.criteria_for_excluding_intermediate_flow,
			sampling_procedure: data?.sampling_procedure ?? processData?.sampling_procedure,
			sampling_sites: data?.sampling_sites ?? processData?.sampling_sites,
			number_of_sampling_sites: data?.number_of_sampling_sites ?? processData?.number_of_sampling_sites,
			sampling_relative_volume: data?.sampling_relative_volume ?? processData?.sampling_relative_volume,
			sampling_absolute_volume: data?.sampling_absolute_volume ?? processData?.sampling_absolute_volume,
			extrapolation: data?.extrapolation ?? processData?.extrapolation,
			time_span_description: data?.time_span_description ?? processData?.time_span_description,
			geography_area_name: data?.geography_area_name ?? processData?.geography_area_name,
			geography_area_description: data?.geography_area_description ?? processData?.geography_area_description,
			geography_sites: data?.geography_sites ?? processData?.geography_sites,
			creator_organization: data?.creator_organization ?? processData?.creator_organization,
			creator_department: data?.creator_department ?? processData?.creator_department,
			creator_name: data?.creator_name ?? processData?.creator_name,
			creator_contact_info: data?.creator_contact_info ?? processData?.creator_contact_info,
			creator_description: data?.creator_description ?? processData?.creator_description,
			data_retrieval_start_at: formattedRetrievalStartAt,
			data_retrieval_end_at: formattedRetrievalEndAt,
			is_data_valid_for_entire_period: data?.is_data_valid_for_entire_period ?? processData?.is_data_valid_for_entire_period,
			technology_level: data?.technology_level ?? processData?.technology_level,
			gis_reference: data?.gis_reference ?? processData?.gis_reference,
			information_source_type: data?.information_source_type ?? processData?.information_source_type,
			reference_uri: data?.reference_uri ?? processData?.reference_uri,
			extend_property: updateExtendPropertys,
			region_id: processData?.region?.id,
			process_ios: updateProcessIos,
			process_parameters: filteredProcessParameters,
			process_data_quality_value_ids: selectQualityIndicatorsData?.map(item => item.level_id),
			process_review_level_value_ids: selectReviewLevelData?.map(item => item.review_level_id),
			attached_file_list: Files,
			allocation_type: inputAllocationType,
			other_relationship_name: otherRelationshipName,
			other_relationship_unit_name: otherRelationshipUnitName,
			allocation_method: inputAllocationMethod,
			allocation_comment: inputAllocationComment,
			allocation_co_product_name: inputAllocationCoProductName,
			allocation_co_product_comment: inputAllocationCoProductComment,
		}

		if (updateProcessParameters && updateProcessParameters.length > 0) {
			// `name` または `value` のどちらかが空または未定義である場合に true を返す
			const hasEmptyFields = updateProcessParameters.some(param =>
				!param.name || param.name === "" || param.value === null || param.value === undefined
			);

			if (hasEmptyFields) {
				setAlertDaialogMessage(t('パラメータ設定に空欄の項目があります'))
				openAlertDialog()
				if (!alertSavedEnabled) {
					console.log("alertSavedEnabled", alertSavedEnabled)
					throw new Error('パラメータ設定に空欄の項目があります');
				}
				return;
			}

			// `name` が英数字でない場合に true を返す
			const hasInvalidNames = updateProcessParameters.some(param =>
				!checkPrameterName(param.name)
			);

			if (hasInvalidNames) {
				setAlertDaialogMessage(t('パラメーター名は英数字で入力してください'))
				openAlertDialog()
				if (!alertSavedEnabled) {
					throw new Error('パラメーター名は英数字で入力してください');
				}
				return;
			}
		}

		// 換算できない配分方式の場合
		if (inputAllocationType === "mass" && massStandardError) {
			setAlertDaialogMessage(t('換算できない配分基準が選択されています'))
			openAlertDialog()
			if (!alertSavedEnabled) {
				throw new Error('換算できない配分基準が選択されています');
			}
			return;
		}
		if (inputAllocationType === "economic" && marketPriceStandardError) {
			setAlertDaialogMessage(t('換算できない配分基準が選択されています'))
			openAlertDialog()
			if (!alertSavedEnabled) {
				throw new Error('換算できない配分基準が選択されています');
			}
			return;
		}

		try {
			await updateProcess(processData?.id, updateParam)
			if (alertSavedEnabled) {
				setAlertDaialogMessage(t('保存しました'))
				openAlertDialog()
			}
			// 登録後更新
			await getProcessForProcessView(Number(params.id))
			setResultData([]);
			setLciResultsData([]);
			setLciaResultsData([]);

			setUnsaved(false);
			setChangeCheckFlow(false);
			setChangeCheckAllocation(false);
			setChangeCheckAdditionInfo(false);
			setChangeCheckExtendProperty(false);
			setchangeCheckParameter(false);

		} catch (error: any) {
			console.log("Error: ", error)
			if(error.response && error.response.data?.detail){
                setAlertDaialogMessage(<>{t('保存に失敗しました')} <br/> {error.response.data?.detail} </>)
            } else {
                setAlertDaialogMessage(<>{t('保存に失敗しました')} <br/> {t('申しわけありませんが、システム管理者までお問い合わせください')}</>)
            }
			openAlertDialog()
			if (!alertSavedEnabled) {
				if(error.response && error.response.data?.detail){
					throw new Error(`${t('保存に失敗しました')}\n ${error.response.data?.detail} `);
				} else {
					throw new Error(`${t('保存に失敗しました')}\n ${t('申しわけありませんが、システム管理者までお問い合わせください')} `);
				}
			}
		}
	};

	//　更新項目だけ抜き出す
	// 入力中間フロー
	const filterInProductData = (data: ProcessIosType[]): {
		amount: number;
		direction: string;
		locale: string;
		exchange_id: number;
		unit_id?: number;
		upper_process_output_id: number | null;
	}[] => {
		return data.map(item => ({
			id: item.id,
			locale: item.locale,
			compatible_product_name: item.compatible_product_name,
			public_comment: item.public_comment,
			private_comment: item.private_comment,
			information_sources: item.information_sources,
			amount_comment: item.amount_comment,
			process_io_no: item.process_io_no,
			formula: item.formula,
			amount: item.amount,
			compatibility_level: item.compatibility_level,
			direction: item.direction,
			exchange_id: item.exchange.id,
			unit_id: item.unit.id ?? item.exchange.unit.id,
			process_io_data_quality_level_ids: item.process_io_data_quality_values?.map(data => data.level_id),
			upper_process_output_id: item.upper_output && item.upper_output.id !== 0 ? item.upper_output.id : null,
		}));
	};

	// 入力基本フロー
	const filterInElementaryFlowData = (data: ProcessIosType[]): {
		amount: number;
		direction: string;
		locale: string;
		exchange_id: number;
		unit_id?: number;
	}[] => {
		return data.map(item => ({
			id: item.id,
			locale: item.locale,
			compatible_product_name: item.compatible_product_name,
			public_comment: item.public_comment,
			private_comment: item.private_comment,
			information_sources: item.information_sources,
			amount_comment: item.amount_comment,
			process_io_no: item.process_io_no,
			formula: item.formula,
			amount: item.amount,
			compatibility_level: item.compatibility_level,
			direction: item.direction,
			exchange_id: item.exchange.id,
			unit_id: item.unit.id ?? item.exchange.unit.id,
			process_io_data_quality_level_ids: item.process_io_data_quality_values?.map(data => data.level_id),
			// upper_output: item.upper_output?.process.id ?? 0
			upper_process_output_id: null,
		}));
	};

	// 出力中間フロー
	const filterOutProductData = (data: ProcessIosType[]): {
		amount: number;
		direction: string;
		locale: string;
		exchange_id: number;
		unit_id?: number;
		process_output_treatment: object;
	}[] => {
		return data.map(item => ({
			id: item.id,
			locale: item.locale,
			compatible_product_name: item.compatible_product_name,
			public_comment: item.public_comment,
			private_comment: item.private_comment,
			information_sources: item.information_sources,
			amount_comment: item.amount_comment,
			process_io_no: item.process_io_no,
			formula: item.formula,
			amount: item.amount,
			compatibility_level: item.compatibility_level,
			direction: item.direction,
			exchange_id: item.exchange.id,
			unit_id: item.unit.id ?? item.exchange.unit.id,
			process_io_data_quality_level_ids: item.process_io_data_quality_values?.map(data => data.level_id),
			process_output_treatment: {
				type: item.treatment?.type || "",
				allocation_rate: isAllocationOut(item.treatment) 
	                ? item.treatment?.allocation_rate ?? 1 // 値が存在しない場合は 1 を設定
    	            : null,
			
				// alternative_process_output_id の優先順位に従い処理
				alternative_process_output_id: isAlternativeOut(item.treatment)
					? (item.treatment.alternative_process_input?.process_input_id !== null
						? null // process_input_id が存在する場合は null をセット
						: item.treatment.alternative_process_input?.upper_process_id) // 存在しない場合に upper_process_id をセット
					: null,

				alternative_process_input_id: isAlternativeOut(item.treatment) ? item.treatment.alternative_process_input?.process_input_id : null,

				alternative_rate: isAlternativeOut(item.treatment) ? item.treatment.alternative_rate : null,

				// disposal_service_process_output_id: isDisposalOut(item.treatment) ? item.treatment.disposal_service_process_input?.upper_process_id : null,

				disposal_service_process_input_no: isDisposalOut(item.treatment) ? item.treatment.disposal_service_process_input?.process_input_no : null,

				// disposal_service_process_input_id: isDisposalOut(item.treatment) ? item.treatment.disposal_service_process_input?.process_input_id : null,
				other_relationship_amount: isAllocationOut(item.treatment) ? item.treatment?.other_relationship_amount : null,
			},
		}));
	};
	// 出力基本フロー
	const filterOutElementaryFlowData = (data: ProcessIosType[]): {
		amount: number;
		direction: string;
		locale: string;
		exchange_id: number;
		unit_id?: number;
	}[] => {
		return data.map(item => ({
			id: item.id,
			locale: item.locale,
			compatible_product_name: item.compatible_product_name,
			public_comment: item.public_comment,
			private_comment: item.private_comment,
			information_sources: item.information_sources,
			amount_comment: item.amount_comment,
			process_io_no: item.process_io_no,
			formula: item.formula,
			amount: item.amount,
			compatibility_level: item.compatibility_level,
			direction: item.direction,
			exchange_id: item.exchange.id,
			unit_id: item.unit.id ?? item.exchange.unit.id,
			process_io_data_quality_level_ids: item.process_io_data_quality_values?.map(data => data.level_id),
		}));
	};

	// 型ガード関数
	const isAllocationOut = (treatment: AllocationOut | Cutoff | DisposalOut | AlternativeOut | null): treatment is AllocationOut => {
		return treatment !== null && treatment.type === 'allocation';
	}

	const isDisposalOut = (treatment: AllocationOut | Cutoff | DisposalOut | AlternativeOut | null): treatment is DisposalOut => {
		return treatment !== null && treatment.type === 'disposal';
	}

	const isAlternativeOut = (treatment: AllocationOut | Cutoff | DisposalOut | AlternativeOut | null): treatment is AlternativeOut => {
		return treatment !== null && treatment.type === 'alternative';
	}

	// 原単位プロセス作成
	const { createUnitProcess, createUnitProcessLoading } = useCreateUnitProcess();
	const [unitProcessAlertDialogOpen, setUnitProcessAlertDialogOpen] = useState(false);

	// ローディングダイアログ
	const [isLoadingDaialogOpen, setIsLoadingDaialogOpen] = useState(false);
	const openLoadingDaialog = () => setIsLoadingDaialogOpen(true);
	const closeLoadingDaialog = () => setIsLoadingDaialogOpen(false);
	const [daialogMessage, setDaialogMessage] = useState<string>("");
	const daialogLoadingMessage = t('原単位プロセスを作成中です');

	const handleUnitProcessAlertDialogOpen = () => {
		setUnitProcessAlertDialogOpen(true);
	};

	const handleUnitProcessAlertDialogClose = () => {
		setUnitProcessAlertDialogOpen(false);
	};

	const handleUnitProcessConfirm = async () => {
		// 原単位プロセス
		if (!resultData || resultData.length === 0) {
			console.log("resultData is Null")
			setUnitProcessAlertDialogOpen(false);
			return;
		}
		try {
			openLoadingDaialog();
			const respoce = await createUnitProcess(resultData[0].process_output.id)
			window.location.href = `/process/process_view/${respoce.id}`;
		} catch (error) {
			alert(error);
		} finally {
			// closeLoadingDaialog
		}
		setUnitProcessAlertDialogOpen(false);
	};

	const AlertDialogTitele = t("原単位プロセスの作成元になるプロセス出力")

	const AlertDialogMessage = (): JSX.Element => {
		if (!resultData || resultData.length === 0) {
			return (
				<>
					{t('原単位プロセスの作成には計算結果が必要です。')}
					<br />
					{t('先にインベントリ分析で計算を行ってから再度実行してください')}
				</>
			);
		} else {
			return (
				<>
					<SelectLabel>
						<Select>
							{resultData.map((item: ProcessCalculate) => (
								<option key={item.process_output.id} value={item.process_output.id} >
									{item.process_output.product_name}
								</option>
							))}
						</Select>
					</SelectLabel>
					{formatToLocalTime(resultData[0].calculated_at)}
					{t('に作成したインベントリ分析結果を使って原単位プロセスを作成します。')}
					<br />
					{t('再計算が必要な場合は、キャンセルしてインベントリ分析タブで計算を行ってから再度実行してください')}
				</>
			);
		}
	};

	// プロセスコピー
	const { createProcessCopy } = useCreateProcessCopy();
	const [processCopyAlertDialogDisableOpen, setProcessCopyAlertDialogDisableOpen] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false); // 処理中かどうかのフラグ

	const handleProcessCopyAlertDialogDisableOpen = () => {
		setProcessCopyAlertDialogDisableOpen(true);
	};

	const handleProcessCopyAlertDialogDisableClose = () => {
		setProcessCopyAlertDialogDisableOpen(false);
	};

	const handleProcessCopyConfirm = async () => {
		if (isProcessing) {
			return;
		}
		setIsProcessing(true); // 処理中にする
		try {
			const responce = await createProcessCopy(processData?.id || 0)
			setProcessCopyAlertDialogDisableOpen(false);
			window.location.href = `/process/process_view/${responce.id}`;
		} catch (error) {
			alert(error);
		} finally {
			setIsProcessing(false); // 処理が終わったらフラグを解除
		}
	};

	const processCopyAlertDialogDisableTitele = ""

	const ProcessCopyAlertDialogDisableMessage = (): JSX.Element => {
		return (
			<>
				{t(`プロセスおよび入出力をコピーします。`)}
				<br />
				{t('製品や添付ファイルなどはコピーされません。')}
			</>
		)
	};

	const { getProcessIosExcel, processIosExcelData } = useGetProcessIosExcel()
	const handleFileDownload = async () => {
		try {

			const failname = processData?.name || 'noname';
			const processId = processData?.id || 0;
			const response = getProcessIosExcel(processId)

			if (!response) {
				throw new Error('Failed to retrieve file data');
			}

			let blob;
			blob = await response;
			const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			const downloadUrl = window.URL.createObjectURL(file);
			const a = document.createElement('a');
			a.href = downloadUrl;
			a.download = failname; // 正しいファイル名を指定
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(downloadUrl);

		} catch (error) {
			console.error('Error downloading file:', error);
		}
	};

	const { getUnitGroups, unitGroupsData, unitGroupsLoading, unitGroupsError } = useGetUnitGroups();
	useEffect(() => {
		if (!databasePacks) {
			return
		}
		try {
			getUnitGroups(databasePacks)
		} catch (error) {
			console.log(error);
		}
	}, [databasePacks]);


	// 保存ダイアログ
	const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
	const openAlertDialog = () => setIsAlertDialogOpen(true);
	const closeAlertDialog = () => setIsAlertDialogOpen(false);
	const [alertDaialogMessage, setAlertDaialogMessage] = useState<string | JSX.Element>("")


	// テキストツリー
	const [treeData, setTreeData] = useState<any[]>([]);

	return (
		<BaseArea key={processData?.id}>
			<ProcessViewContext.Provider value={{
				userData,
				unitGroupsData,
				processData,
				processIos,
				inElementaryFlow,
				outElementaryFlow,
				inProduct,
				outProduct,
				databasePacks,
				processParameters,
				updateProcessParameters,
				categoriesListData,
				enumsData,
				dataQualityIndicatorsData,
				selectQualityIndicatorsData,
				qualityIndicatorsDataList,
				reviewLevelTypeData,
				selectReviewLevelData,
				reviewLevelsDataList,
				inputAllocationType,
				otherRelationshipUnitName,
				unsaved,
				setUnsaved,
				setProcessIos,
				setInElementaryFlow,
				setOutElementaryFlow,
				setInProduct,
				setOutProduct,
				setProcessParameters,
				setUpdateProcessParameters,
				setSelectQualityIndicatorsData,
				setQualityIndicatorsDataList,
				setSelectReviewLevelData,
				setReviewLevelsDataList,
				setInputAllocationType,
				setOtherRelationshipUnitName,
				otherRelationshipName,
				inputAllocationMethod,
				inputAllocationComment,
				inputAllocationCoProductName,
				inputAllocationCoProductComment,
				setOtherRelationshipName,
				setInputAllocationMethod,
				setInputAllocationComment,
				setInputAllocationCoProductName,
				setInputAllocationCoProductComment,
				extendProperty,
				updateExtendPropertys,
				setExtendProperty,
				setUpdateExtendPropertys,
				methodsData,
				previewImage,
				setPreviewImage,
				imageSrc,
				setImageSrc,
				imageName,
				setImageName,
				imageFile,
				setImageFile,
				referenceFiles,
				setReferenceFiles,
				inputCharacterizationDisplayFormatValue,
				setInputCharacterizationDisplayFormatValue,
				onSubmit,
				resultData,
				setResultData,
				changeCheckAdditionInfo,
				setChangeCheckAdditionInfo,
				changeCheckExtendProperty,
				setChangeCheckExtendProperty,
				changeCheckParameter,
				setchangeCheckParameter,
				changeCheckFlow,
				setChangeCheckFlow,
				segregatedData,
				changeCheckAllocation,
				setChangeCheckAllocation,
				isInitializedAllocationInfo,
				setIsInitializedAllocationInfo,
				lciaResultsData,
				setLciaResultsData,
				lciaResultsLoading,
				lciaResultsError,
				selectModelIdList,
				setSelectModelIdList,
				lciResultsData,
				setLciResultsData,
				lciResultsLoading,
				setSavedAlertEnabled,
				getLciData,
				calculateAndGetResultsLoading,
				setCalculateAndGetResultsLoading,
				calculatedDateData,
				getLciCalculatedDate,
				treeData,
				setTreeData,
				unitConversionsData,
				kgId,
				userDefaultCurrencyId,
				userDefaultCurrencyName,
				setMassStandardError,
				setMarketPriceStandardError
			}}>

				<MessageDaialog
					open={isAlertDialogOpen}
					onClose={closeAlertDialog}
					message={alertDaialogMessage}
				/>

				{processLoading &&
					<FadeLoader
						color="#48bdbb"
						height={10}
						radius={2}
						width={5}
					/>
				}
				{createUnitProcessLoading && 
					<LoadingDialog
						open={isLoadingDaialogOpen}
						onClose={closeLoadingDaialog}
						loading={createUnitProcessLoading}
						loading_message={daialogLoadingMessage}
						result_message={daialogMessage}
					/>
				}
				{processError && <ErrorMsg>{processError}</ErrorMsg>}
				{processData &&
					<form onSubmit={handleSubmit(onSubmit)}>
						<StyledHeading>
							<div>
								<StyledHeadingPText>{t('プロセス名')}</StyledHeadingPText>
								<StyledHeadingTitle>
									{processData?.name}
								</StyledHeadingTitle>
							</div>
							<div>
								<StyledHeadingPText>{t('選択中のデータベースパック')}</StyledHeadingPText>
								<StyledHeadingTitle>
									{processData?.database_pack.name}
								</StyledHeadingTitle>
							</div>
							<StyledHeadingNav>
								<StyledHeadingNavList>
									<StyledHeadingNavListItem>
										<A onClick={handleUnitProcessAlertDialogOpen}>{t('原単位プロセスの作成')}</A>
										<AlertDialog
											open={unitProcessAlertDialogOpen}
											onClose={handleUnitProcessAlertDialogClose}
											onConfirm={handleUnitProcessConfirm}
											title={AlertDialogTitele}
											message={AlertDialogMessage()}
										/>
									</StyledHeadingNavListItem>

									<StyledHeadingNavListItem>
										<A onClick={handleProcessCopyAlertDialogDisableOpen}>{t('コピーの作成')}</A>
										<AlertDialogDisable
											open={processCopyAlertDialogDisableOpen}
											onClose={handleProcessCopyAlertDialogDisableClose}
											onConfirm={handleProcessCopyConfirm}
											title={processCopyAlertDialogDisableTitele}
											message={ProcessCopyAlertDialogDisableMessage()}
											disableConfirmButton={isProcessing} // OKボタンの無効化をフラグで制御
										/>
									</StyledHeadingNavListItem>

									<StyledHeadingNavListItem>
										<StyledHeadingNavListItemButton type='button' onClick={() => { hundleUndo() }}>
											<StyledHeadingNavListItemButtonText>
												{t('変更を破棄')}
											</StyledHeadingNavListItemButtonText>
										</StyledHeadingNavListItemButton>
									</StyledHeadingNavListItem>

									<StyledHeadingNavListItem>
										{!processData?.is_database_pack_master &&
											<StyledHeadingNavListItemButton type='submit' disabled={isDisabled} onClick={() => { setSavedAlertEnabled(true) }} >
												<StyledHeadingNavListItemButtonText>
													{(
														changeCheckFlow ||
														changeCheckAdditionInfo ||
														changeCheckExtendProperty ||
														changeCheckParameter ||
														changeCheckAllocation
													) && <span>*</span>}
													{t('保存する')}
												</StyledHeadingNavListItemButtonText>
											</StyledHeadingNavListItemButton>
										}
									</StyledHeadingNavListItem>

								</StyledHeadingNavList>
							</StyledHeadingNav>
						</StyledHeading>
						<StyledPageNav>
							<StyledPageNavList>

								{/* タブのボタン */}
								<StyledPageNavCurrent>
									<TabButton type='button' onClick={() => handleTabClick('tab1')} isActive={activeTab === 'tab1'}>
										<TabButtonSpan>{t('入出力情報')}</TabButtonSpan>
									</TabButton>
								</StyledPageNavCurrent>
								<StyledPageNavCurrent>
									<TabButton type='button' onClick={() => handleTabClick('tab2')} isActive={activeTab === 'tab2'}>
										<TabButtonSpan>{t('追加情報')}</TabButtonSpan>
									</TabButton>
								</StyledPageNavCurrent>
								<StyledPageNavCurrent>
									<TabButton type='button' onClick={() => handleTabClick('tab3')} isActive={activeTab === 'tab3'}>
										<TabButtonSpan>{t('配分')} </TabButtonSpan>
									</TabButton>
								</StyledPageNavCurrent>
								<StyledPageNavCurrent>
									<TabButton type='button' onClick={() => handleTabClick('tab4')} isActive={activeTab === 'tab4'}>
										<TabButtonSpan>{t('パラメータ')}</TabButtonSpan>
									</TabButton>
								</StyledPageNavCurrent>

								<StyledPageNavCurrent>
									<TabButton type='button' onClick={() => handleTabClick('tab5')} isActive={activeTab === 'tab5'}>
										<TabButtonSpan>{t('テキストツリー情報')}</TabButtonSpan>
									</TabButton>
								</StyledPageNavCurrent>

								<StyledPageNavCurrent>
									<TabButton type='button' onClick={() => handleTabClick('tab6')} isActive={activeTab === 'tab6'}>
										<TabButtonSpan>{t('計算')}</TabButtonSpan>
									</TabButton>
								</StyledPageNavCurrent>
							</StyledPageNavList>
							{activeTab === 'tab1' &&
								<StyledPageNavButton>
									<StyledExportButton
										type='button'
										onClick={() => handleFileDownload()}
									>
										{t('入力情報をエクセル出力')}
									</StyledExportButton>
								</StyledPageNavButton>}
						</StyledPageNav>

						{/* タブの内容 */}
						{activeTab === 'tab1' && <TabPanel><IOInfo register={register} errors={errors} /></TabPanel>}
						{activeTab === 'tab2' && <TabPanel><AdditionInfo watch={watch} control={control} register={register} errors={errors} /></TabPanel>}
						{activeTab === 'tab3' && <TabPanel><ProsessAllocation register={register} errors={errors} /></TabPanel>}
						{activeTab === 'tab4' && <TabPanel><ProcessParameterSetting /></TabPanel>}
						{activeTab === 'tab5' && 
							<TabPanel>
								<TextTreeDisplay />
							</TabPanel>
						}
						{activeTab === 'tab6' && <TabPanel><ProcessCalculation register={register} errors={errors} /></TabPanel>}
					</form>
				}
			</ProcessViewContext.Provider>
		</BaseArea>
	);
};

export default ProcessView;


const TabButton = styled.button.withConfig({
	shouldForwardProp: (prop) => prop !== 'isActive'
}) <{ isActive: boolean }>`
  padding: 5px 16px;
  background-color: var(--color-gray--01);
  color: black;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 20px;

  ${(props) =>
		props.isActive &&
		css`
      background-color: var(--color-site-secondary);
      color: #fff !important;
    `
	}

  &:hover {
    background-color: var(--color-site-secondary);
    color: #fff !important;
    ${(props) =>
		props.isActive &&
		css`
      background-color: var(--color-site-secondary);
      `
	}
  }
`

const TabButtonSpan = styled.span`
  font-family: "Noto Sans JP", sans-serif;
  font-size: 12px;
`

const TabPanel = styled.div`
  // padding: 20px;
  margin-top: 24px;
`;

const StyledHeading = styled.div`
  width: calc(100% + 32px);
  margin-bottom: 40px;
  margin-left: -16px;
  padding: 24px 16px;
  background-color: #fff;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
  position: sticky;
  top: 90px;
  left: 0;
  z-index: 10;
`

const StyledHeadingPText = styled.p`
  color: var(--color-txt-tertiary);
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
`

const StyledHeadingTitle = styled.h2`
  color: var(--color-txt-secondary);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.25;
`

const StyledHeadingNav = styled.nav`
  margin-left: auto;  
`
const StyledHeadingNavList = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 16px;
`
const StyledHeadingNavListItem = styled.li`
  font-size: 13px;
  font-weight: 500;
`
const StyledHeadingNavListItemButton = styled.button`
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid var(--color-site-secondary);

  padding: 4px 16px;

  display: inline-flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  gap: 0 4px;
  &:hover {
    color: #fff;
    & > span {
      color: #fff;
    }
    background-color: var(--color-site-secondary);
  }
`
const StyledHeadingNavListItemButtonText = styled.span`
    font-size: 13px;
    font-weight: 500;
    color: var(--color-site-secondary);
    font-family: "Noto Sans JP", sans-serif;
`
const StyledExportButton = styled.button`
    background-color: var(--color-site-secondary);
    color: #fff;

    padding: 8px 24px;
    border-radius: 4px;
    white-space: nowrap;

    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    gap: 0 4px;

    appearance: none;
    border: none;
    font: inherit;
    outline: none;    
`

const StyledPageNav = styled.nav`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 24px;
`

const StyledPageNavList = styled.ul`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 12px;
`

const StyledPageNavCurrent = styled.li`
`

const StyledPageNavButton = styled.p`
`

const SelectLabel = styled.label`
	width: min(100%, 540px);
	position: relative;
	display: block;
	flex: 1 0 1%;
`
const Select = styled.select`
	position: relative;
	width: 100%;
	padding: 12px 32px 12px 12px;
	cursor: pointer !important;
	// color: var(--color-line-primary);
	border: 1px solid var(--color-line-primary);
	border-radius: 4px;
	background-color: #fff;
	font-weight: 400;
	line-height: 1.25;
	margin-bottom: 10px;
`