import React, { useContext, useEffect, useState } from 'react';
import Modal from '@common/modal/Modal';
import { GetReferredIo, GetUnitConversions, SubsystemIOCaseStudyProductOut, SubsystemIODefinedProductOut, UnitGroupList } from '@typeList/types';
import { useGetProductDetail, useGetReferredIo, useGetUnitConversionsByFromUnitId, useGetUnitGroups } from '@hooks/useBackendApi';
import { CaseStudyViewContext } from '@pages/case_study/CaseStudyView';
import { getCurrentUserInfo } from '@services/Cookies';
import styled from 'styled-components';

import iconEdit from '@images/product_system/icon_edit.svg'
import iconReload from '@images/button/icon_reload.svg';
import { useTranslation } from 'react-i18next';
import { RequiredSpan } from '@styles/Common';
import { formatNumber, checkFormula } from '@utils/utils';
import FlowParameterSettingListModal from '@specific/process/flow_parameter_setting/FlowParameterSettingListModal';
import { useUnitHooks } from '@hooks/useUnitHook';

interface SubsystemInputProductEditModalType {
    subsystemName: string;
    currentNodeData: SubsystemIODefinedProductOut;
    onSave: (updatedIO: SubsystemIODefinedProductOut) => void;
}

const SubsystemInputProductEditModal: React.FC<SubsystemInputProductEditModalType> = ({
    subsystemName,
    currentNodeData,
    onSave
}) => {
    const {t} = useTranslation();
    const context = useContext(CaseStudyViewContext);
    const { userData, unitGroupsData, unitConversionsData, databasePacks } = context;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const closeInitModal = () => {
        // 状態を初期化
        setInputProductName(currentNodeData?.exchange.name || "");
        setInputAmount(
            formatNumber(
                currentNodeData?.amount ?? 0,
                userData?.significant_figures ?? 2,
                userData?.use_decimal_notation ?? false,
                userData?.decimal_places ?? 2
            ) ?? null
        );
        setInputAmountFormula(currentNodeData?.formula || "");
        setInputPublicComment(currentNodeData?.public_comment || "");
        setInputPrivateComment(currentNodeData?.private_comment || "");
        setInputUnitId(String(currentNodeData?.unit.id) || "0");
        setInputUnitName(currentNodeData?.unit.name || "");
        setInputCompatibleProductName(currentNodeData?.compatible_product_name || "");
        setInputAmountComment(currentNodeData?.amount_comment || "");
        setInputInformationSources(currentNodeData?.information_sources || "");
        setInputCompatibilityLevel(currentNodeData?.compatibility_level || 0);
        setInputReferredIoId(currentNodeData.upper_output?.id || 0); // ここを追加
    
        // モーダルを閉じる
        setIsModalOpen(false);
    };

    const [inputProductId, setInputProductId] = useState<number>(currentNodeData?.exchange.id ?? 0);
    // 製品詳細
    const { getProductDetail, getProductDetailData, getProductDetailError, setGetProductDetailLoading } = useGetProductDetail();
    useEffect(() =>{
        if(inputProductId){
            getProductDetail(inputProductId)
        }
    },[inputProductId])


    const [inputProductName, setInputProductName] = useState<string>(currentNodeData?.exchange.name || "");
    const [inputAmount, setInputAmount] = useState<number | string | null>(
        formatNumber(
            currentNodeData?.amount ?? 0,
            userData?.significant_figures ?? 2,
            userData?.use_decimal_notation ?? false,
            userData?.decimal_places ?? 2
        ) ?? null // 初期値を null として管理
    );
    useEffect(() =>{
        // 編集後に再度開くと変換されないのでダイアログ展開時にもセットする
        if(isModalOpen) {
            setInputAmount(
                formatNumber(
                    currentNodeData?.amount ?? 0,
                    userData?.significant_figures ?? 2,
                    userData?.use_decimal_notation ?? false,
                    userData?.decimal_places ?? 2
                ) ?? null // 初期値を null として管理
            )
        }
    },[isModalOpen])

    const [inputAmountFormula, setInputAmountFormula] = useState<string | undefined>(currentNodeData?.formula || "");
    const [inputPublicComment, setInputPublicComment] = useState<string>(currentNodeData?.public_comment || "");
    const [inputPrivateComment, setInputPrivateComment] = useState<string>(currentNodeData?.private_comment || "");
    const [inputUnitId, setInputUnitId] = useState<string>(String(currentNodeData?.unit.id) || "0");
    const [inputUnitName, setInputUnitName] = useState<string>(currentNodeData?.unit.name || "");
    const [inputUnitCode, setInputUnitCode] = useState<string>("");
    const [inputUnitLocale, setInputUnitLocale] = useState<string>("");
    const [inputUnitPrivateComment, setInputUnitPrivateComment] = useState<string | null>(null);
    const [inputUnitPublicComment, setInputUnitPublicComment] = useState<string | null>(null);
    const [inputCompatibleProductName, setInputCompatibleProductName] = useState<string>(currentNodeData?.compatible_product_name || "");
    const [inputAmountComment, setInputAmountComment] = useState<string>(currentNodeData?.amount_comment || "");
    const [inputInformationSources, setInputInformationSources] = useState<string>(currentNodeData?.information_sources || "");
    const [inputCompatibilityLevel, setInputCompatibilityLevel] = useState<number>(currentNodeData?.compatibility_level || 0);

    // エラーチェック
    const [productNameError, setProductNameError] = useState<any>(null);
    const [amountError, setAmountError] = useState<any>(null);
    const [amountFormulaError, setAmountFormulaError] = useState<any>(null);
    const validateCheck = (): boolean => {
        setProductNameError(null);
        setAmountError(null);
        setAmountFormulaError(null);
        if (!inputProductName) {
            setProductNameError(t('製品名は必須です'));
        }
        if (inputAmount === null || inputAmount === undefined) {
            setAmountError(t('流量は必須です'));
        }
        if (!checkFormula(inputAmountFormula)) {
            setAmountFormulaError(t('数式に使えない文字が混ざっています。使える文字は、英数と._+-*/()です'));
        }        
        if (!inputProductName || inputAmount === null || inputAmount === undefined || !checkFormula(inputAmountFormula)) {
            console.log("validateCheck false")
            return false;
        }
        return true;
    }

    // 単位
    const [unitList, setUnitList] = useState<UnitGroupList[]>([]);
    const [unitConversionList, setUnitConversionList] = useState<GetUnitConversions[] | undefined>();

    // フローの単位が所属するグループでフィルター
    const getParentIdByUnitId = (unitGroups: UnitGroupList[], unitId: number): number | undefined => {
        for (const group of unitGroups) {
            for (const unit of group.units) {
                if (unit.id === unitId) {
                    return group.id;
                }
            }
        }
        return undefined;
    };

    useEffect(() => {
        if (unitGroupsData && getProductDetailData) {
            const list = unitGroupsData?.filter(units => units.id === getParentIdByUnitId(unitGroupsData, getProductDetailData?.unit.id ?? 0))
            setUnitList(list)
        }
    }, [unitGroupsData, getProductDetailData])

    useEffect(() => {
        if (unitGroupsData) {
            const unitConversionCodes = getProductDetailData?.unit_conversions;
            setUnitConversionList(unitConversionCodes)
        }
    }, [getProductDetailData])

    const { applyUnitConversion } = useUnitHooks()    

    // 単位換算
    const {getUnitConversionsByFromUnitId, getUnitConversionsByFromUnitIdData} = useGetUnitConversionsByFromUnitId();
    useEffect(() =>{
        // 単位切り替え時に換算リストを取得する
        try {
            if(databasePacks) {
                getUnitConversionsByFromUnitId(databasePacks, currentNodeData?.exchange.id , Number(inputUnitId))
            }
        } catch (error) {
            console.log(error);
        }
    },[inputUnitId]);



    // 製品を参照しているプロセス入出力
    const [inputReferredIoId, setInputReferredIoId] = useState<number>(currentNodeData.upper_output?.id || 0);
    const [inputReferredIoName, setInputReferredIoName] = useState<string>("");
    const { getReferredIo, referredIoData, referredIoLoading, referredIoError } = useGetReferredIo();
    useEffect(() => {
        // console.log("currentNodeData", currentNodeData)
        if (currentNodeData) {
            getReferredIo(currentNodeData?.exchange?.id || 0);
        }
    }, [currentNodeData]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
        const value = event.target.value;
        switch (key) {
            case "name":
                setInputProductName(value);
                break;
            case "amount":
                if (value === "") {
                    setInputAmount(null); // 空白の場合は null として扱う
                } else {
                    setInputAmount(Number(value)); // 数値として管理する
                }
                break;
            case "amount_formula":
                setInputAmountFormula(value);
                break;
            case "compatible_product_name":
                setInputCompatibleProductName(value);
                break;
        }
    }

    const handleInputChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>, key: string) => {
        switch (key) {
            case "public_comment":
                setInputPublicComment(event.target.value);
                break;
            case "private_comment":
                setInputPrivateComment(event.target.value);
                break;
            case "amount_comment":
                setInputAmountComment(event.target.value);
                break;
            case "information_sources":
                setInputInformationSources(event.target.value);
                break;
        }
    }

    const handleInputChangeSelect = async (event: React.ChangeEvent<HTMLSelectElement>, key: string) => {
        switch (key) {
            case "unit":
                const selectedValue = event.target.value;
                setInputUnitId(selectedValue);
                // unitListの中のunitsから選択されたユニットを探す
                for (const group of unitGroupsData ?? []) {
                    const selectedUnit = group.units.find(unit => unit.id === Number(selectedValue));
                    if (selectedUnit) {
                        setInputUnitName(selectedUnit.name);
                        setInputUnitCode(selectedUnit.code);
                        setInputUnitLocale(selectedUnit.locale);
                        setInputUnitPrivateComment(selectedUnit.private_comment);
                        setInputUnitPublicComment(selectedUnit.public_comment);
                        break;
                    }
                }

                // 単位換算の適用
                const newAmount = applyUnitConversion(
                    Number(inputAmount),
                    Number(inputUnitId),
                    Number(selectedValue),
                    getUnitConversionsByFromUnitIdData,
                    getProductDetailData,
                );
                setInputAmount(Number(newAmount));

                break;
            case "process_name":
                setInputReferredIoId(Number(event.target.value));
                const selectedOption = event.target.selectedOptions[0];
                const text = selectedOption.text;
                if(text !== "選択しない"){
                    setInputReferredIoName(text);
                }
                break;
            case "compatibility_level":
                setInputCompatibilityLevel(Number(event.target.value));
                break;
        }
    }

    const handleClickOK = async () => {
        if(!validateCheck()) {
            return;
        }
        const updatedParam: SubsystemIODefinedProductOut = {
            ...param,
            exchange: {
                ...param.exchange,
            }
        };

        onSave(updatedParam);
        closeModal();
    }

    // テーブル追加データ
    const param: SubsystemIODefinedProductOut = {
        locale: currentNodeData?.locale,
        compatible_product_name: inputCompatibleProductName,
        public_comment: inputPublicComment,
        private_comment: inputPrivateComment,
        information_sources: inputInformationSources,
        amount_comment: inputAmountComment,
        process_io_no: currentNodeData.process_io_no,
        formula: inputAmountFormula || "",
        amount: Number(inputAmount),
        direction: "in",
        compatibility_level: inputCompatibilityLevel,
        id: currentNodeData.id,
        global_id: "",
        unit: {
            id: Number(inputUnitId),
            name: inputUnitName || ""
        },
        type: "defined_product",
        exchange: {
            name: inputProductName,
            id: currentNodeData.exchange.id || 0,
            global_id: currentNodeData.exchange.global_id,
            unit: {
                id: currentNodeData?.unit.id || 0,
                name: currentNodeData?.unit.name || ""
            }
        },
        upper_output: {
            id: inputReferredIoId || 0,
            process_id: referredIoData?.find((referredIo: GetReferredIo) =>{return referredIo.id === inputReferredIoId})?.process_id || 0,
            compatible_product_name: "",
            product_name: inputReferredIoName,
            product_type: "product",
            amount: referredIoData?.find((referredIo: GetReferredIo) =>{return referredIo.id === inputReferredIoId})?.amount || 0,
            unit: {
                id: 0,
                name: ""
            }
        }
    }

    return (
        <>
            <NodeBoxDlButton onClick={openModal} type='button' >
                <NodeBoxFlowButtonIconDiv>
                    <NodeBoxFlowButtonIcon></NodeBoxFlowButtonIcon>
                </NodeBoxFlowButtonIconDiv>
            </NodeBoxDlButton>
            <Modal isOpen={isModalOpen} onClose={closeInitModal}>
                <Inner>
                    <Title>{t('製品システム：フローおよび製品の編集（既存製品）')}</Title>
                    <Section>
                        <Dl>
                            <DlWrap>
                                <Dt>{t('製品名')}<RequiredSpan>※</RequiredSpan></Dt>
                                <Dd>
                                    <DisabledInput
                                        type="text"
                                        onChange={(event) => handleInputChange(event, 'name')}
                                        value={inputProductName ?? ''}
                                        disabled
                                    />
                                    {productNameError && <ErrorMsg>{productNameError}</ErrorMsg>}
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('流量の数式')}</Dt>
                                <Dd>
                                    <Input
                                        type="text"
                                        value={inputAmountFormula ?? ""}
                                        onChange={(event) => handleInputChange(event, 'amount_formula')}
                                    />
                                    <FlowParameterSettingListModal inputAmountFormula={inputAmountFormula} setInputAmountFormula={setInputAmountFormula} />
                                </Dd>
                                {amountFormulaError && <><Dd></Dd><ErrorMsg>{amountFormulaError}</ErrorMsg></>}
                            </DlWrap>
                            <DlWrap>
                                <Dt></Dt>
                                <Dd>
                                {t('*数式はケーススタディ保存時に評価され流量に反映されます')}
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('流量')}<RequiredSpan>※</RequiredSpan></Dt>
                                <DdShort>
                                    <AmountInput
                                        type="number"
                                        value={inputAmount ?? ""}
                                        min={"0"}
                                        onChange={(event) => handleInputChange(event, 'amount')}
                                        disabled={!!inputAmountFormula}
                                    />
                                    <UnitSelectLabel>
                                        <UnitSelect
                                            value={inputUnitId ?? ''}
                                            onChange={(event) => handleInputChangeSelect(event, 'unit')}
                                        >
                                            {unitList?.map((units) => (
                                                units.units.map((unit) => (
                                                    <option key={unit.id} value={unit.id}>
                                                        {unit.name}
                                                    </option>
                                                ))
                                            ))}
                                            {unitConversionList?.map((units, index) => (
                                                <option key={units.to_unit.id} value={units.to_unit.id}>
                                                    {units.to_unit.code}
                                                </option>
                                            ))}
                                        </UnitSelect>
                                    </UnitSelectLabel>
                                    {amountError && <ErrorMsg>{amountError}</ErrorMsg>}
                                </DdShort>
                            </DlWrap>

                            <DlWrap>
                                <Dt>{t('上流定義済みプロセス')}</Dt>
                                <Dd>
                                    <SelectLabel>
                                        <Select
                                            onChange={(event) => handleInputChangeSelect(event, 'process_name')}
                                            value={inputReferredIoId ?? ''}
                                        >
                                            <option>{t('選択しない')}</option>
                                            {referredIoData
                                                ?.filter((referredIo) => referredIo.direction === 'out'  && referredIo.process_output_treatment_type === "allocation")
                                                .map((referredIo) => (
                                                    <option key={referredIo.id} value={referredIo.id}>
                                                        {referredIo.process_name}
                                                    </option>
                                                ))}
                                        </Select>
                                    </SelectLabel>
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('下流サブシステム')}</Dt>
                                <Dd>
                                    <DisabledInput
                                        type="text"
                                        disabled
                                        value={subsystemName}
                                    />
                                </Dd>
                            </DlWrap>
                        </Dl>
                    </Section>
                    <Section>
                        <Title>{t('適合性')}</Title>
                        <Dl>
                            <DlWrap>
                                <Dt>{t('情報源のフロー名')}</Dt>
                                <Dd>
                                    <FlowNameInput
                                        type="text"
                                        value={inputCompatibleProductName ?? ''}
                                        onChange={(event) => handleInputChange(event, 'compatible_product_name')}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('適合度')}</Dt>
                                <Dd>
                                    <SelectLabel>
                                        <Select
                                            onChange={(event) => handleInputChangeSelect(event, 'compatibility_level')}
                                            value={inputCompatibilityLevel ?? "1"}
                                        >
                                            <option value="1">{t('よい')}</option>
                                            <option value="2">{t('普通')}</option>
                                            <option value="3">{t('悪い')}</option>
                                        </Select>
                                    </SelectLabel>
                                </Dd>
                            </DlWrap>
                        </Dl>
                    </Section>
                    <Section>
                        <Title>{t('コメント')}</Title>
                        <Dl>
                            <DlWrap>
                                <Dt>{t('プライベートコメント')}</Dt>
                                <Dd>
                                    <TextArea
                                        cols={100}
                                        rows={3}
                                        onChange={(event) => handleInputChangeTextArea(event, 'private_comment')}
                                        value={inputPrivateComment ?? ''}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('公開コメント')}</Dt>
                                <Dd>
                                    <TextArea
                                        cols={100} 
                                        rows={3}
                                        onChange={(event) => handleInputChangeTextArea(event, 'public_comment')}
                                        value={inputPublicComment ?? ''}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('情報源')}</Dt>
                                <Dd>
                                    <TextArea
                                        cols={50}
                                        rows={2}
                                        onChange={(event) => handleInputChangeTextArea(event, 'information_sources')}
                                        value={inputInformationSources ?? ''}
                                    />
                                </Dd>
                            </DlWrap>
                            <DlWrap>
                                <Dt>{t('流量の計算方法')}</Dt>
                                <Dd>
                                    <TextArea
                                        cols={50}
                                        rows={2}
                                        onChange={(event) => handleInputChangeTextArea(event, 'amount_comment')}
                                        value={inputAmountComment ?? ''}
                                    />
                                </Dd>
                            </DlWrap>
                        </Dl>
                    </Section>
                    <ButtonWrapper>
                        <UpdateButton type='button' onClick={handleClickOK}>
                            <ButtonIconWrapper>
                                <ButtonIcon></ButtonIcon>
                            </ButtonIconWrapper>
                            <ButtonText>{t('変更する')}</ButtonText>
                        </UpdateButton>
                    </ButtonWrapper>
                </Inner>
            </Modal>
        </>
    )
}

export default SubsystemInputProductEditModal;

const Inner = styled.div`
    width: 100%;
    padding: 120px 10%;
    margin: 0 auto;
`

const NodeBoxDlButton = styled.button`
  white-space: nowrap;
  cursor: pointer;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
`

const NodeBoxFlowButtonIconDiv = styled.div`
`

const NodeBoxFlowButtonIcon = styled.span`
mask: url(${iconEdit}) no-repeat center center / contain;
-webkit-mask: url(${iconEdit}) no-repeat center center / contain;
display: block;
width: 24px;
height: 24px;
background: var(--color-txt-primary);
`


const Title = styled.h2`
font-size: 18px;

padding: 0 0 20px;
margin-bottom: 20px;
border-bottom: 1px solid var(--color-line-primary);
font-weight: 500;

line-height: 1.25;
`

const Section = styled.div`
    margin-bottom: 60px;
`

const Dl = styled.dl`
`

const DlWrap = styled.div`
margin-bottom: 8px;

display: grid;
align-items: center;
grid-template-columns: 27.0833333333% minmax(0, 1fr);
gap: 6px 40px;
`

const Dt = styled.dt`
margin-bottom: 0;
font-size: 16px;
font-weight: 500;
line-height: 1.25;
`
const Dd = styled.dd`
display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 16px;
`
const DdShort = styled.dd`
width: min(100%, 340px);

display: flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: flex-start;
gap: 16px;
`

const Input = styled.input`
width: 81.6176470588%;

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const DisabledInput = styled.input`
width: 81.6176470588%;

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: var(--color-bg-primary);
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const AmountInput = styled.input<{ disabled?: boolean }>`
width: 50%;

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: ${(props) => (props.disabled ? 'var(--color-bg-primary)' : '#fff')};
font-size: 16px;
font-weight: 400;
line-height: 1.25;
&:disabled {
    pointer-events: none;
}
`

const FlowNameInput = styled.input`
width: min(100%, 340px);

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const UnitSelectLabel = styled.label`
width: 50%;
position: relative;
display: block;
`
const UnitSelect = styled.select`
color: rgb(85, 85, 85);

position: relative;
width: 100%;
padding: 10px 32px 10px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const SelectLabel = styled.label`
width: min(100%, 340px);
position: relative;
display: block;
`

const Select = styled.select`
color: rgb(85, 85, 85);

position: relative;
width: 100%;
padding: 10px 32px 10px 12px;
cursor: pointer !important;
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const DownInput = styled.input`
width: 100%;
background-color: var(--color-bg-primary);

padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
`

const TextArea = styled.textarea`
padding: 12px;
color: var(--color-txt-primary);
border: 1px solid var(--color-line-primary);
border-radius: 4px;
background-color: #fff;
font-size: 16px;
font-weight: 400;
line-height: 1.25;
width: 100%;
`

const ButtonWrapper = styled.div`
padding: 8px 24px;
text-align: right;
`
const Button = styled.button`
padding: 8px 24px;
border-radius: 20px;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
background-color: var(--color-gray--01);
gap: 0 4px;

-moz-appearance: none;
appearance: none;
border: none;
font: inherit;
outline: none;

&:hover {
    background-color: var(--color-site-secondary);
    text-decoration: none !important;

    div span {
        background-color: #fff;
    }
    span {
        color: #fff;
    }
}
`

const UpdateButton = styled.button`
padding: 2px 12px;
border-radius: 20px;

display: inline-flex;
align-items: center;
flex-direction: row;
flex-wrap: nowrap;
justify-content: center;
cursor: pointer;
white-space: nowrap;
background-color: var(--color-gray--01);
gap: 0 4px;

-moz-appearance: none;
appearance: none;
border: none;
font: inherit;
outline: none;

&:hover {
    background-color: var(--color-site-secondary);
    text-decoration: none !important;

    div span {
        background-color: #fff;
    }
    span {
        color: #fff;
    }
}
`

const ButtonIconWrapper = styled.div`
`
const ButtonIcon = styled.span`
background-color: var(--color-site-secondary);

mask: url(${iconReload}) no-repeat center center / contain;
-webkit-mask: url(${iconReload}) no-repeat center center / contain;
display: block;
width: 100%;
height: 100%;
width: 24px;
height: 24px;
`
const ButtonText = styled.span`
font-size: 12px;
font-weight: 500;
`

const ErrorMsg = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: var(--color-error-primary);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
`;